import { useFormik } from "formik"
import React from "react"; // , { useState }
import { Link } from "react-router-dom"
// import { Form } from "react-bootstrap";
import { FormattedMessage, injectIntl } from "react-intl"
import { connect } from "react-redux"
import * as Yup from "yup"

import { Button } from "react-bootstrap"
import { APP_NAME } from '../../../../data/appData'; // , FOOTER_LINKS
import { formikValidClass } from '../../../../utils/Q'
import Flex from "../../../components/Flex"
import Password from '../../../components/Password'
import { login } from "../_redux/authCrud"
import * as auth from "../_redux/authRedux"
// import Flex from '../../../components/Flex';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/
/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "system", // admin@demo.com
  password: "", // demo
};

function Login(props) {
  const { intl, authLoading } = props;
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });
  const redirectTo = new URLSearchParams(window.location.search).get('ReturnUrl');

  const getMessage = () => { return new URLSearchParams(window.location.search).get('status') }

  const enableLoading = () => {
    props.setLoading(true);
  };

  const disableLoading = () => {
    props.setLoading(false);
  };

  // const getInputClasses = (fieldname) => {
  //   if (formik.touched[fieldname] && formik.errors[fieldname]) {
  //     return "is-invalid";
  //   }
  //   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
  //     return "is-valid";
  //   }
  //   return "";
  // };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setStatus("");
      setTimeout(() => {
        const data = { username: values.email, password: values.password };
        login(data)
          .then(({ data: { success, message, data } }) => {
            // console.log("masuk sini", props);
            // setSubmitting(false);
            if (success) {
              props.login(data.requiresTwoFactor);
            } else {
              disableLoading();
              setStatus(message);
              setSubmitting(false);
            }
          })
          .catch(() => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  const message = getMessage();

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div className="mb-10 text-white">
        <h3>Sign in To {APP_NAME}</h3>
        <p className="text-white mt-4">Enter your details to login to your account:</p>
      </div>
      {/*begin::Form*/}
      <form noValidate
        onSubmit={formik.handleSubmit}
        className={"form fv-plugins-bootstrap fv-plugins-framework text-left" + (formik.isSubmitting ? " cprogress" : "")}
      >
        <fieldset disabled={formik.isSubmitting}>
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status ?? message}</div>
            </div>
          )}

          <div className="form-group mb-5 fv-plugins-icon-container">
            <input type="text" required spellCheck={false}
              placeholder="Email or Username"
              className={"form-control h-auto form-control-solid" + formikValidClass(formik, "email")}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>

          <div className="form-group mb-5 fv-plugins-icon-container">
            <Password required
              placeholder="Password"
              id="password"
              className={"h-auto form-control-solid" + formikValidClass(formik, "password")}
              value={formik.values.password}
              onChange={formik.handleChange}
            />

            {formik.touched.password && formik.errors.password && (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            )}
          </div>

          <div className="form-group fv-plugins-icon-container">
            <Link
              to="/authentication/forgot-password"
              id="kt_login_forgot"
              className="float-right mb-3 text-white"
            >
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
          </div>

          <div className="form-group mb-5 fv-plugins-icon-container">{/*  opacity-60 */}
            <Button
              id="kt_login_signin_submit"
              type="submit"
              disabled={formik.isSubmitting}
              className="btn btn-primary font-weight-bold px-9"
              block
            >
              <span>Sign In</span>
              {authLoading && <span className="ml-3 spinner spinner-white" />}
            </Button>
          </div>
            <Flex className="flex-column flex-md-row" style={{ gap: '5px' }}>
                <div className="col-12 col-md-12 p-0">
                    <a
                        href={redirectTo ? `/auth/login?returnUrl=${redirectTo}` : "/auth/login"}
                        className="btn btn-default text-dark btn-block font-weight-bold my-3"
                        id="kt_login_microsoft"
                    >
                        Sign In With <img alt="login_microsoft" src="/icons/MS_logo.svg" style={{ height: '16px' }} className="ml-2" />
                    </a>
                </div>
            </Flex>
            <div className="d-flex justify-center mt-4">
                <a href="https://docs.unifiplatform.com/" target="_blank" rel="noopener noreferrer" className="email text-center text-white m-auto">Need Help?</a>
            </div>
        </fieldset>
      </form>
      {/*end::Form*/}
    </div>
  );
}

const mapStateToProps = state => {
  return {
    authLoading: state.auth.authLoading,
  }
}

export default injectIntl(connect(mapStateToProps, auth.actions)(Login));
