import React, { Suspense, lazy } from "react";
import { Redirect, Switch } from "react-router-dom";

import { LayoutSplashScreen } from "../_metronic/layout";
// import { BuilderPage } from "./pages/BuilderPage";
// import { MyPage } from "./pages/MyPage";
// import { DashboardPage } from "./pages/DashboardPage";

import AuthorizeRoute from "./AuthorizeRoute";

const DashboardPage = lazy(() => import('./pages/dashboard'));
const TenantDashboardPage = lazy(() => import('./pages/dashboard/tenant'));
const DashboardListPage = lazy(() => import('./pages/dashboard-list'));
// const TestComponent = lazy(() => import('./pages/TestComponent'));

const ApplicationMenuPage = lazy(() => import("./modules/ApplicationMenu/pages"));
const ApplicationUserPage = lazy(() => import("./modules/Settings/pages/ApplicationUser"));
const UserProfile = lazy(() => import("./modules/Profiles/pages/profile"));
const UserDetailPage = lazy(() => import("./modules/Settings/pages/ApplicationUser/detail"));

const RolesPermissions = lazy(() => import('./modules/Settings/pages/RolesPermissions'));
const Settings = lazy(() => import('./modules/Settings/pages/index'));
const ProfileSettings = lazy(() => import('./modules/Profiles/pages/index'));
const OrganizationsPage = lazy(() => import('./modules/Settings/pages/organization/index'));
const ManageMenu = lazy(() => import('./modules/Settings/pages/manage/menu/pages')); 
const CurrencyPage = lazy(() => import('./modules/Settings/pages/currency'));

const TenantManagement = lazy(() => import('./modules/TenantManagement/pages'));
const TenantAdd = lazy(() => import('./modules/TenantManagement/pages/Add'));
const TenantEdit = lazy(() => import('./modules/TenantManagement/pages/tenant/Detail')); // Edit | tenant/Detail

const PaymentType = lazy(() => import('./modules/PaymentType/pages'));
const TenantPayment = lazy(() => import('./modules/TenantPayment/pages/payment'));
const AddTenantPayment = lazy(() => import('./modules/TenantPayment/pages/payment/Add'));
const TenantBilling = lazy(() => import('./modules/TenantPayment/pages/billing'));
const AddBilling = lazy(() => import('./modules/TenantPayment/pages/billing/Add'));
const TenantLicense = lazy(() => import('./modules/TenantManagement/pages/license'));// formLicense
const AddLicense = lazy(() => import('./modules/TenantManagement/pages/license/FormInput'));

const TenantBillingPage = lazy(() => import('./modules/TenantManagement/pages/billing'));
const AddBillingPage = lazy(() => import('./modules/TenantManagement/pages/billing/FormInput'));
const EditBillingPage = lazy(() => import('./modules/TenantManagement/pages/billing/FormEdit'));

const TenantPaymentPage = lazy(() => import('./modules/TenantManagement/pages/payment'));
const AddPaymentPage = lazy(() => import('./modules/TenantManagement/pages/payment/FormInput'));
const EditPaymentPage = lazy(() => import('./modules/TenantManagement/pages/payment/FormEdit'));

const LicensePricingPage = lazy(() => import('./modules/TenantManagement/pages/license-pricing'));
const AddLicensePricingPage = lazy(() => import('./modules/TenantManagement/pages/license-pricing/FormInput'));
const EditLicensePricingPage = lazy(() => import('./modules/TenantManagement/pages/license-pricing/FormEdit'));

const ChannelPage = lazy(() => import('./modules/TenantManagement/pages/channel'));
const AddChannelPage = lazy(() => import('./modules/TenantManagement/pages/channel/Add'));
const EditChannelPage = lazy(() => import('./modules/TenantManagement/pages/channel/Add'));

const ContractTypePage = lazy(() => import('./modules/TenantManagement/pages/contract-type'));

const LicenseTypePage = lazy(() => import('./modules/TenantManagement/pages/license-type'));

// const ReportPage = lazy(() => import('./modules/TenantManagement/pages/report/index'));
const ReportPage = lazy(() => import('./modules/Reports/Pages'));
const ReportDesignerPage = lazy(() => import('./pages/report-designer'));
// const ReportViewerPage = lazy(() => import('./pages/report-viewer'));

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {/* Redirect from root URL to /dashboard. */}
                <Redirect exact from="/" to="/home" />{/* /dashboard */}

                <AuthorizeRoute path="/dashboard" component={DashboardPage} />{/* /dashboard */}
                {/* <AuthorizeRoute path="/builder" component={BuilderPage} /> */}
                {/* <AuthorizeRoute path="/my-page" component={MyPage} /> */}

                <AuthorizeRoute path="/home" component={TenantDashboardPage} />
								{/* REPORT */}
                <AuthorizeRoute path="/dashboard-list" component={DashboardListPage} />
                <AuthorizeRoute path="/report-designer" component={ReportDesignerPage} />
                {/* <AuthorizeRoute path="/report-viewer" component={ReportViewerPage} /> */}
                <AuthorizeRoute exact path="/tenant-management/report" component={ReportPage} />

                {/* PAYMENT METHOD */}
                <AuthorizeRoute exact path="/payment/payment-type" component={PaymentType} />
                <AuthorizeRoute exact path="/payment/tenant-payment" component={TenantPayment} />
                <AuthorizeRoute exact path="/payment/tenant-payment/create" component={AddTenantPayment} />
                <AuthorizeRoute exact path="/payment/tenant-billing" component={TenantBilling} />
                <AuthorizeRoute exact path="/payment/tenant-billing/create" component={AddBilling} />

                {/* SETTINGS */}
                <AuthorizeRoute exact path="/settings" component={Settings} />
                <AuthorizeRoute path="/settings/roles-permissions" component={RolesPermissions} />
                <AuthorizeRoute path="/settings/menu" component={ApplicationMenuPage} />
                <AuthorizeRoute path="/settings/organization" component={OrganizationsPage} />
                <AuthorizeRoute exact path="/settings/application-user" component={ApplicationUserPage} />
                <AuthorizeRoute path="/settings/application-user/detail" component={UserDetailPage} />
                <AuthorizeRoute path="/settings/manage/menu" component={ManageMenu} />
                <AuthorizeRoute exact path="/settings/currency" component={CurrencyPage} />

                <AuthorizeRoute exact path="/app/user" component={ProfileSettings} />
                <AuthorizeRoute path="/app/user/profile" component={UserProfile} />

                <AuthorizeRoute exact path="/tenant-management/tenant" component={TenantManagement} />
                <AuthorizeRoute path="/tenant-management/tenant/add" component={TenantAdd} />
                <AuthorizeRoute path="/tenant-management/tenant/edit" component={TenantEdit} />

                <AuthorizeRoute exact strict path="/tenant-management/license" component={TenantLicense} />
                <AuthorizeRoute path="/tenant-management/license/add" component={AddLicense} />
                <AuthorizeRoute path="/tenant-management/license/edit" component={AddLicense} />

                <AuthorizeRoute exact path="/tenant-management/billing" component={TenantBillingPage} />
                <AuthorizeRoute path="/tenant-management/billing/add" component={AddBillingPage} />
                <AuthorizeRoute path="/tenant-management/billing/edit" component={EditBillingPage} />

                <AuthorizeRoute exact path="/tenant-management/payment" component={TenantPaymentPage} />
                <AuthorizeRoute path="/tenant-management/payment/add" component={AddPaymentPage} />
                <AuthorizeRoute path="/tenant-management/payment/edit" component={EditPaymentPage} />

                <AuthorizeRoute exact path="/tenant-management/license-pricing" component={LicensePricingPage} />
                <AuthorizeRoute path="/tenant-management/license-pricing/add" component={AddLicensePricingPage} />
                <AuthorizeRoute path="/tenant-management/license-pricing/edit" component={EditLicensePricingPage} />{/* /:id */}

                <AuthorizeRoute exact path="/tenant-management/channel" component={ChannelPage} />
                <AuthorizeRoute exact path="/tenant-management/channel/add" component={AddChannelPage} />
                <AuthorizeRoute exact path="/tenant-management/channel/edit" component={EditChannelPage} />

                <AuthorizeRoute exact path="/tenant-management/contract-type" component={ContractTypePage} />

                <AuthorizeRoute exact path="/tenant-management/license-type" component={LicenseTypePage} />

                {/* <AuthorizeRoute path="/devs/test-components" component={TestComponent} /> */}

                <Redirect to="error/error-v1" />
            </Switch>
        </Suspense>
    );
}
